import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@progress/kendo-react-buttons';
import { ACCESS_TOKEN, USER_DATA } from "../../../constants/applicationConstants";
import { useAuth } from "../../Core/Context/AuthContext";
import { useNotification } from "../../Core/Context/NotificationContext";

/**
 * LoginForm component for handling user authentication.
 * @param {Object} props - Component props.
 * @param {Function} props.setIsLoader - Function to set loader state.
 * @returns {JSX.Element} Rendered component.
 */
const LoginForm = ({ setIsLoader }) => {
    const { addNotification } = useNotification();
    const { loginWithRedirect } = useAuth0();
    const { updateUser, checkTokens } = useAuth();
    const { user, isAuthenticated, isLoading, getAccessTokenSilently, error } = useAuth0();
    const dashboardPath = '/';

    // Redirect to dashboard if tokens are present
    React.useEffect(() => {
        if (checkTokens()) {
            window.location.replace(dashboardPath);
        }
    }, []);

    // Effect to retrieve access token when user is authenticated
    useEffect(() => {
        /**
         * Fetch access token and store it in session storage.
         * Redirect to dashboard upon successful authentication.
         */
        const fetchAccessToken = async () => {
            setIsLoader(true);
            try {
                const token = await getAccessTokenSilently();
                sessionStorage.setItem(ACCESS_TOKEN, token);
                window.location.replace(dashboardPath);
            } catch (error) {
                addNotification('error', "Please Try Again");
                console.error('Error getting access token:', error);
            }
        };

        if (isAuthenticated) {
            sessionStorage.setItem(USER_DATA, JSON.stringify(user));
            fetchAccessToken();
        }
        if (error) {
            addNotification('error', error.message);
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    /**
     * Handle user login by initiating login with Auth0.
     */
    const handleLogin = async () => {
        setIsLoader(true);
        loginWithRedirect();
    };

    return (
        <Button
            className="k-w-100 ai-button-primary"
            onClick={handleLogin}
            rounded={'medium'}
            size={'medium'}
        >
            Login
        </Button>
    );
};

export default LoginForm;
